import React, { Component, Suspense } from 'react'
import { Navigate, HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import './appcss/global.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const AuthenticateLogin = React.lazy(() => import('./authenticate/Authenticate_login'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              path="*"
              name="Home"
              element={
                sessionStorage.getItem(process.env.REACT_APP_USERSARADATA1) ? (
                  <AuthenticateLogin />
                ) : (
                  <Navigate to="login" replace />
                )
              }
            />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}
export default App
